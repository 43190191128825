import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import AppsIcon from "@mui/icons-material/Apps";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BusinessIcon from "@mui/icons-material/Business";
import CommentIcon from "@mui/icons-material/Comment";
import EmailIcon from "@mui/icons-material/Email";
import SettingIcon from "@mui/icons-material/Settings";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import EngineeringIcon from "@mui/icons-material/Engineering";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import PeopleIcon from "@mui/icons-material/People";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import FactoryIcon from "@mui/icons-material/Factory";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AddchartOutlined,
  AnalyticsOutlined,
  LanguageOutlined,
  ReportProblemOutlined,
  RequestQuoteOutlined,
  TopicOutlined,
} from "@mui/icons-material";
import { isManage360 } from "../utils/helper";

const SidebarMenu = () => {
  const navigate = useNavigate();
  const [configExpanded, setConfigExpanded] = React.useState(false);
  const [reportExpanded, setReportExpanded] = React.useState(false);
  const [socialExpanded, setSocialExpanded] = React.useState(false);
  const _isManage360 = React.useMemo(() => isManage360(), []);

  const { pathname } = useLocation();
  const page = React.useMemo(() => {
    const parts = pathname.split("/");
    let page = "?";
    if (parts.length >= 3) page = parts[1] + "/" + parts[2];
    else if (parts.length >= 2) page = parts[1];
    return page;
  }, [pathname]);

  return (
    <List component="nav">
      <ListItemButton
        onClick={() => navigate("/dashboard")}
        selected={page == "dashboard"}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
      <ListItemButton
        onClick={() => navigate("/clients")}
        selected={page == "clients"}
      >
        <ListItemIcon>
          <BusinessIcon />
        </ListItemIcon>
        <ListItemText primary="Clients" />
      </ListItemButton>
      <ListItemButton
        onClick={() => navigate("/talents")}
        selected={page == "talents"}
      >
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Talents" />
      </ListItemButton>
      <ListItemButton
        onClick={() => navigate("/jobs")}
        selected={page == "jobs"}
      >
        <ListItemIcon>
          <HomeRepairServiceIcon />
        </ListItemIcon>
        <ListItemText primary="Jobs" />
      </ListItemButton>
      {!_isManage360 && (
        <>
          <ListItemButton onClick={() => setReportExpanded(!reportExpanded)}>
            <ListItemIcon>
              <AddchartOutlined />
            </ListItemIcon>
            <ListItemText
              primary="Finance Menu"
              primaryTypographyProps={{ fontWeight: 600 }}
            />
            {reportExpanded ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={reportExpanded} timeout="auto" unmountOnExit>
            <List
              component="div"
              disablePadding
              style={{ backgroundColor: "#f5f5f5" }}
            >
              <ListItemButton
                onClick={() => navigate("/finances")}
                selected={page == "finances"}
              >
                <ListItemIcon>
                  <AttachMoneyIcon />
                </ListItemIcon>
                <ListItemText primary="General Finances" />
              </ListItemButton>
              <ListItemButton
                onClick={() => navigate("/reports/finance-report")}
                selected={page == "reports/finance-report"}
              >
                <ListItemIcon>
                  <RequestQuoteOutlined />
                </ListItemIcon>
                <ListItemText primary="Financial Report" />
              </ListItemButton>
              <ListItemButton
                onClick={() => navigate("/withdrawals")}
                selected={page == "withdrawals"}
              >
                <ListItemIcon>
                  <BusinessCenterIcon />
                </ListItemIcon>
                <ListItemText primary="Withdrawals" />
              </ListItemButton>
            </List>
          </Collapse>
        </>
      )}
      <ListItemButton onClick={() => setConfigExpanded(!configExpanded)}>
        <ListItemIcon>
          <SettingIcon />
        </ListItemIcon>
        <ListItemText
          primary="Settings"
          primaryTypographyProps={{ fontWeight: 600 }}
        />
        {configExpanded ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={configExpanded} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          style={{ backgroundColor: "#eee" }}
        >
          <ListItemButton
            onClick={() => navigate("/business-verticals")}
            selected={page == "business-verticals"}
          >
            <ListItemIcon>
              <FactoryIcon />
            </ListItemIcon>
            <ListItemText primary="Industries" />
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate("/skills")}
            selected={page == "skills"}
          >
            <ListItemIcon>
              <EngineeringIcon />
            </ListItemIcon>
            <ListItemText primary="Skills" />
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate("/certifications")}
            selected={page == "certifications"}
          >
            <ListItemIcon>
              <CardMembershipIcon />
            </ListItemIcon>
            <ListItemText primary="Certifications" />
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate("/reasons")}
            selected={page == "reasons"}
          >
            <ListItemIcon>
              <CommentIcon />
            </ListItemIcon>
            <ListItemText primary="Reasons" />
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate("/email-templates")}
            selected={page == "email-templates"}
          >
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText primary="Email Templates" />
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate("/app-updates")}
            selected={page == "app-updates"}
          >
            <ListItemIcon>
              <AppsIcon />
            </ListItemIcon>
            <ListItemText primary="App Updates" />
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate("/admins")}
            selected={page == "admins"}
          >
            <ListItemIcon>
              <AdminPanelSettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Admin Users" />
          </ListItemButton>
        </List>
      </Collapse>
      {/* Social */}
      <ListItemButton onClick={() => setSocialExpanded(!socialExpanded)}>
        <ListItemIcon>
          <DeviceHubIcon />
        </ListItemIcon>
        <ListItemText
          primary="Social Network"
          primaryTypographyProps={{ fontWeight: 600 }}
        />
        {socialExpanded ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={socialExpanded} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          style={{ backgroundColor: "#eee" }}
        >
          <ListItemButton
            onClick={() => navigate("/social/feeds")}
            selected={page == "social/feeds"}
          >
            <ListItemIcon>
              <TopicOutlined />
            </ListItemIcon>
            <ListItemText primary="Social Feeds" />
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate("/social/resources")}
            selected={page == "social/resources"}
          >
            <ListItemIcon>
              <LanguageOutlined />
            </ListItemIcon>
            <ListItemText primary="Resources" />
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate("/social/user-feedbacks")}
            selected={page == "social/user-feedbacks"}
          >
            <ListItemIcon>
              <ReportProblemOutlined />
            </ListItemIcon>
            <ListItemText primary="User Feedbacks" />
          </ListItemButton>
        </List>
      </Collapse>
      <ListItemButton
        onClick={() =>
          window.open(
            "https://analytics.google.com/analytics/web/?authuser=0#/p307958142/reports/"
          )
        }
        selected={page === "analytics"}
      >
        <ListItemIcon>
          <AnalyticsOutlined />
        </ListItemIcon>
        <ListItemText primary="Analytics" />
      </ListItemButton>
    </List>
  );
};
export default SidebarMenu;
