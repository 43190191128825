import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
  GridSortModel,
  GridRowModel,
  GridToolbarContainer,
  GridRowClassNameParams,
  GridValueGetterParams,
  GridToolbarExport,
} from "@mui/x-data-grid";
import {
  useActivateTalentMutation,
  useDeleteTalentMutation,
  useDisableTalentMutation,
  useGetTalentsQuery,
  useRestoreTalentMutation,
} from "../../services/talent.service";
import LinearProgress from "@mui/material/LinearProgress";
// import { Stack, Paper, Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal";
import NoResultsOverlay from "../../components/NoResultOverlay";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import useDebounce from "../../hooks/useDebounce";
import { useTitle } from "../../hooks/useTitle";
import { Talent, UserStatus } from "../../types/users";
import { useNavigate } from "react-router-dom";
import { formatMoney, isManage360 } from "../../utils/helper";
import {
  Avatar,
  Chip,
  IconButton,
  Rating,
  Typography,
  colors,
} from "@mui/material";
import LocationOn from "@mui/icons-material/LocationOn";
import { FilterAlt, FilterAltOutlined } from "@mui/icons-material";
import ModalTalentFilter from "./ModalTalentFilter";
import { useAppSelector } from "../../hooks/store";
import { RootState } from "../../app/store";
import dayjs from "dayjs";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";

const TableToolbar = ({
  onSearch,
  onCreate,
}: {
  onSearch: (query?: string) => void;
  onCreate: () => void;
}) => {
  const navigate = useNavigate();
  const [query, setQuery] = React.useState<string | undefined>(undefined);
  const debounceQuery = useDebounce(query, 500);
  const [showFilter, setShowFilter] = React.useState(false);

  const searchFilter = useAppSelector(
    (state: RootState) => state.ui.talentFilter
  );

  const filterOn = React.useMemo(() => {
    return (
      (Array.isArray(searchFilter.skills) && searchFilter.skills.length > 0) ||
      !!searchFilter.state ||
      !!searchFilter.city ||
      !!searchFilter.country
    );
  }, [searchFilter]);

  React.useEffect(() => {
    onSearch(debounceQuery);
  }, [debounceQuery]);

  return (
    <GridToolbarContainer
      style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 5 }}
    >
      <TextField
        margin="dense"
        label="Enter to search.."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {!!query && (
        <Button variant="text" sx={{ mt: 0 }} onClick={() => setQuery("")}>
          Reset
        </Button>
      )}
      <Button
        variant={filterOn ? "contained" : "text"}
        onClick={() => setShowFilter(true)}
        sx={{ ml: 1, mt: 0 }}
      >
        {filterOn ? <FilterAlt /> : <FilterAltOutlined />} Filter{" "}
        {filterOn ? "ON" : ""}
      </Button>
      <Stack style={{ flex: 1 }} direction="row" justifyContent="flex-end">
        <Button
          variant="outlined"
          onClick={() => navigate(`/talents/map`)}
          sx={{ mr: 1 }}
        >
          <LocationOn /> Show Map
        </Button>
        {/* <Button variant="outlined" onClick={onCreate}>
          <AddIcon /> Add Talent
        </Button> */}
        <GridToolbarExport
          sx={{ ml: 2 }}
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{ allColumns: true, fileName: "Talents" }}
        />
      </Stack>
      <ModalTalentFilter
        open={showFilter}
        onClose={() => setShowFilter(false)}
      />
    </GridToolbarContainer>
  );
};

const TalentsPage = () => {
  useTitle("Talents");
  const navigate = useNavigate();
  const _isManage360 = React.useMemo(() => isManage360(), []);

  const [deleteModal, setDeleteModal] = React.useState<{
    open: boolean;
    row: GridRowModel<Talent> | null;
  }>({
    open: false,
    row: null,
  });
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>(
    undefined
  );

  const searchFilter = useAppSelector(
    (state: RootState) => state.ui.talentFilter
  );

  const { data, isLoading, isFetching } = useGetTalentsQuery({
    page,
    pageSize,
    sortField: sortModel.length > 0 ? sortModel[0].field : undefined,
    sortMode: sortModel.length > 0 ? sortModel[0].sort : undefined,
    search: searchQuery,
    ...searchFilter,
  });
  const rowCount = React.useMemo(() => data?.meta.total, [data]);

  const [doDelete, { isLoading: deleting }] = useDeleteTalentMutation();
  const [doActivate, { isLoading: activing }] = useActivateTalentMutation();
  const [doDisable, { isLoading: inactiving }] = useDisableTalentMutation();
  const [doRestore, { isLoading: restoring }] = useRestoreTalentMutation();

  const handleDeleteRow = (row: GridRowModel<Talent>) => {
    setDeleteModal({ open: true, row });
  };
  const handleEditRow = (row: GridRowModel<Talent>) => {
    navigate(`/talents/${row.id}/edit`);
  };
  const handleRestoreRow = async (row: GridRowModel<Talent>) => {
    try {
      await doRestore(row.id).unwrap();
      toast.success("Data restored");
    } catch (err) {}
  };

  const rows: GridRowsProp | undefined = React.useMemo(
    () => data?.data,
    [data]
  );

  const columns: GridColDef[] = React.useMemo(
    () =>
      [
        {
          field: "talent_name",
          headerName: "Talent",
          minWidth: 300,
          flex: 1,
          sortingOrder: ["desc", "asc", null],
          filterable: true,
          renderCell: (params: GridRenderCellParams<undefined, Talent>) => (
            <Stack direction={"row"} alignItems="center">
              <Avatar
                alt={`${params.row.first_name}`}
                src={`${params.row?.photo?.[0]?.original_url}`}
                sx={{ mr: 1 }}
              />
              <Link
                onClick={() => navigate(`/talents/${params.row.id}`)}
                component="button"
                variant="body2"
              >
                {params.row.first_name} {params.row.last_name}
              </Link>
              <Rating
                value={params.row.total_rating}
                readOnly
                size="small"
                sx={{ ml: 2 }}
              />
            </Stack>
          ),
          disableExport: true,
        },
        {
          field: "name",
          headerName: "Talent Name",
          hide: true,
          valueGetter: (params: GridValueGetterParams<undefined, Talent>) =>
            `${params.row.full_name}`,
        },
        {
          field: "total_rating",
          headerName: "Rating",
          hide: true,
        },
        {
          field: "phone_number",
          headerName: "Phone",
          hide: true,
        },
        {
          field: "email",
          headerName: "Email",
          headerAlign: "center",
          minWidth: 200,
          sortable: true,
          filterable: true,
          align: "center",
        },
        {
          field: "balance_decrypt",
          headerName: "Balance",
          headerAlign: "center",
          //minWidth: 120,
          sortable: false,
          filterable: false,
          align: "center",
          valueGetter: (params: GridValueGetterParams<undefined, Talent>) =>
            `${formatMoney(params.row.balance_decrypt)}`,
        },
        {
          field: "physical_address_1",
          headerName: "physical_address_1",
          hide: true,
        },
        {
          field: "zip",
          headerName: "Zip",
          hide: true,
        },
        {
          field: "city",
          headerName: "City",
          headerAlign: "center",
          minWidth: 120,
          sortable: true,
          filterable: false,
          align: "center",
        },
        {
          field: "state",
          headerName: "State",
          headerAlign: "center",
          minWidth: 120,
          sortable: true,
          filterable: false,
          align: "center",
          valueGetter: (params: GridValueGetterParams<undefined, Talent>) =>
            `${params.row.state?.toUpperCase() || ""}`,
        },
        {
          field: "status",
          headerName: "Status",
          headerAlign: "center",
          minWidth: 120,
          sortable: true,
          filterable: true,
          align: "center",
          renderCell: (params: GridRenderCellParams<undefined, Talent>) => {
            if (!!params.row?.deleted_at)
              return <Typography variant="body2">Deleted</Typography>;
            return (
              <Chip
                label={params.row.status.toUpperCase()}
                size="small"
                color={params.row.status == "active" ? "success" : undefined}
                sx={{ fontSize: 12 }}
              />
            );
          },
        },
        {
          field: "control",
          headerName: "Toggle",
          headerAlign: "center",
          //minWidth: 10,
          sortable: false,
          filterable: false,
          align: "center",
          renderCell: (params: GridRenderCellParams<undefined, Talent>) => {
            if (!!params.row.deleted_at) {
              return (
                <Button
                  variant="outlined"
                  size="small"
                  color="info"
                  onClick={() => handleRestoreRow(params.row)}
                >
                  Restore
                </Button>
              );
            } else if (params.row.status === "pending") {
              //return null;
              return (
                <Button
                  variant="contained"
                  size="small"
                  color="info"
                  onClick={() => handleUpdateStatusRow(params.row, "active")}
                >
                  Approve
                </Button>
              );
            } else if (params.row.status === "active") {
              return (
                <Button
                  variant="contained"
                  size="small"
                  color="error"
                  onClick={() => handleUpdateStatusRow(params.row, "in-active")}
                >
                  Disable
                </Button>
              );
            } else if (params.row.status === "in-active") {
              return (
                <Button
                  variant="contained"
                  size="small"
                  color="success"
                  onClick={() => handleUpdateStatusRow(params.row, "active")}
                >
                  Enable
                </Button>
              );
            } else {
              return null;
            }
          },
          disableExport: true,
        },
        {
          field: "rate",
          headerName: "Talent Rate",
          hide: true,
          valueGetter: (params: GridValueGetterParams<undefined, Talent>) =>
            `${params.row.min_pay || "?"} - ${params.row.max_pay || "?"}`,
        },
        {
          field: "commute_radius",
          headerName: "Commute Radius (miles)",
          hide: true,
        },
        {
          field: "created_at",
          headerName: "Date Created",
          sortingOrder: ["asc", "desc", null],
          headerAlign: "center",
          sortable: true,
          filterable: true,
          minWidth: 120,
          align: "center",
          valueGetter: (params: GridValueGetterParams<undefined, Talent>) =>
            `${dayjs(params.row?.created_at).format("MMM DD, YYYY")}`,
        },
        {
          field: "control2",
          headerName: "",
          headerAlign: "center",
          sortable: false,
          filterable: false,
          align: "center",
          renderCell: (params: GridRenderCellParams<undefined, Talent>) => {
            if (!!params.row.deleted_at) return null;
            return (
              <Stack direction={"row"}>
                <IconButton onClick={() => handleEditRow(params.row)}>
                  <Edit fontSize="small" />
                </IconButton>
                <IconButton onClick={() => handleDeleteRow(params.row)}>
                  <DeleteOutline fontSize="small" color="error" />
                </IconButton>
              </Stack>
            );
          },
          disableExport: true,
        },
      ].filter((it) => {
        if (_isManage360) {
          return !["rate", "balance_decrypt"].includes(it.field);
        } else return true;
      }) as GridColDef[],
    [_isManage360]
  );

  const handleSearch = React.useCallback((query?: string) => {
    setSearchQuery(query);
  }, []);

  const handleCreate = React.useCallback(() => {
    navigate(`/talents/create`);
  }, []);

  // const handleFilter = React.useCallback((props) => {
  //   setSearchFilter(props);
  // }, []);

  const closeDeleteModal = () =>
    setDeleteModal({ ...deleteModal, open: false });

  const handleDelete = async () => {
    if (deleteModal.row?.id) {
      try {
        await doDelete(deleteModal.row.id).unwrap();
        closeDeleteModal();
        toast.success("Successfully deleted");
      } catch (err) {
        closeDeleteModal();
      }
    }
  };

  const handleUpdateStatusRow = async (
    row: GridRowModel<Talent>,
    newStatus: string
  ) => {
    try {
      if (newStatus === "active") {
        await doActivate(row.id).unwrap();
      }
      if (newStatus === "in-active") {
        await doDisable(row.id).unwrap();
      }
      toast.success("Successfully updated");
    } catch (err) {}
  };

  const CustomToolbar = React.useMemo(
    () => () =>
      (
        <TableToolbar
          onSearch={handleSearch}
          onCreate={handleCreate}
          // onFilter={handleFilter}
        />
      ),
    [handleSearch, handleCreate]
  );

  const getRowClassName = (params: GridRowClassNameParams) => {
    return !!params.row.deleted_at ? "deleted" : "";
  };

  return (
    <Paper style={{ height: "85vh" }}>
      <DataGrid
        rows={rows || []}
        columns={columns}
        paginationMode="server"
        rowCount={rowCount}
        page={page}
        onPageChange={(newPage) => setPage(newPage)}
        pageSize={pageSize}
        rowsPerPageOptions={[25, 50, 100]}
        onPageSizeChange={(newSize) => setPageSize(newSize)}
        sortModel={sortModel}
        sortingMode="server"
        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        loading={isLoading || isFetching}
        components={{
          LoadingOverlay: LinearProgress,
          NoResultsOverlay: NoResultsOverlay,
          Toolbar: CustomToolbar,
        }}
        disableSelectionOnClick
        // disableColumnFilter
        // rowHeight={42}
        // density="compact"
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#f5f5f5",
            borderTop: "1px solid #ddd",
          },
          "& .deleted": {
            fontStyle: "italic",
            color: "#aaa",
          },
        }}
        getRowClassName={getRowClassName}
      />
      <ConfirmDeleteModal
        open={deleteModal.open}
        onClose={closeDeleteModal}
        onDelete={handleDelete}
        message={`Delete User#${deleteModal.row?.id} '${deleteModal.row?.full_name}' ?`}
      />
    </Paper>
  );
};
export default TalentsPage;
