import { Certification, UserCertification } from "./certification";
import { Company } from "./company";
import { UserEducation } from "./education";
import { Language } from "./language";
import { Photo } from "./photo";
import { Skill } from "./skill";
import { UserWorkExperience } from "./workExperience";
import { Coordinate } from "./common";
import { Country } from "./country";
import { BusinessVertical } from "./vertical";

export enum UserStatus {
  ACTIVE = "active",
  PENDING = "pending",
  IN_ACTIVE = "in-active",
}

export enum UserWorkStatus {
  PART_TIME = "part-time",
  SOLE_PROPRIETOR = "sole-proprietor",
  ENTREPRENEUR = "entrepreneur",
}

export interface User {
  id: number;
  role_id: number;
  first_name: string | null;
  last_name: string | null;
  full_name: string | null;
  phone_number: string | null;
  email: string | null;
  email_verified_at: Date | null;
  // security_questions: string | null;
  // security_answer: string | null;
  instagram: string | null;
  facebook: string | null;
  linkedin: string | null;
  website: string | null;
  //
  country_id: number;
  zip: string | null;
  state: string | null;
  city: string | null;
  physical_address_1: string | null;
  physical_address_2: string | null;

  photo: Array<Photo>;
  country: Country;

  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
}

export type Admin = Omit<
  User,
  | "country_id"
  | "zip"
  | "state"
  | "zip"
  | "physical_address_1"
  | "physical_address_2"
  | "bio"
>;

export interface Talent extends User {
  bio: string | null;
  min_pay: number;
  max_pay: number;
  commute_radius: number;
  total_rating: number;
  total_job_success_rate: number;
  total_earning: number;
  balance_decrypt: number;
  coordinate: Coordinate;
  covid_vaccinated: boolean;
  allow_background_check: boolean;
  allow_drug_test: boolean;
  status: UserStatus;

  skills: Array<Skill>;
  business_verticals: Array<BusinessVertical>;
  languages: Array<Language>;
  certifications: Array<UserCertification>;
  //business_verticals
  educations: Array<UserEducation>;
  work_experiences: Array<UserWorkExperience>;

  talent: AdditionalUserInfo;
}

export interface Manager extends User {
  company: Company;
}

export interface AdditionalUserInfo {
  avail_monday: boolean;
  avail_tuesday: boolean;
  avail_wednesday: boolean;
  avail_thursday: boolean;
  avail_friday: boolean;
  avail_saturday: boolean;
  avail_sunday: boolean;
  is_valid_us: boolean;
  work_status: UserWorkStatus;
}
